import React, { useRef, useState, useEffect, useCallback } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import ElementsForm from "@/components/stripe/ElementsForm";
import { FiShoppingCart } from "react-icons/fi";
import useSWR from "swr";
import fetcher from "@/utils/fetcher";
import { useAuth } from "@/lib/auth";
import firebase from "firebase";
import {
  Flex,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  Button,
  Badge,
  Box,
  List,
  ListItem,
  ListIcon,
  FormControl,
  FormErrorMessage,
  FormLabel,
  useToast,
  Icon,
  Input,
  Textarea,
  FormHelperText
} from "@chakra-ui/react";
import { FiCheck } from "react-icons/fi";
import NextLink from "next/link";
import ServiceList from "@/components/ServiceList";
import AddPaymentDetailModal from "./AddPaymentDetail";
import SelectDateTimeSessionModal from "./SelectDateTimeSession";
import ThankYouModal from "./ThankYou";
import SessionInvoiceModal from "./SessionInvoice";
import useCustomToast from "hooks/useCustomToast";
import CardDetail from "./CardDetail";
import Spinner from "@/components/Spinner";
import { parse, format } from "date-fns";
import StartEle from "@/utils/StarElement";
const BookingStepsModal = ({ project, sessionUser, session, service, formStep, isDetail }) => {
  const toast = useCustomToast();
  const { user } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [userData, setuserData] = useState(null);
  const { data, mutate } = useSWR(`/api/user/${user?.uid}`, fetcher);
  const [isOpenModal, setToggle] = useState(false);
  const [price, setPrice] = useState();
  const [plan, setPlan] = useState();
  const [message, setMessage] = useState();
  const [selected, setSelected] = useState(service ? service?.id : null);
  const [selectedService, setselectedService] = useState(service ? service : null);
  const [careerErr, setCareerErr] = useState(null);
  const [step, setStep] = useState(formStep ? formStep : isDetail ? 1 : 0);
  const [isNext, setIsNext] = useState(false);
  const [cardData, setCardData] = useState(null);
  const [sessionData, setSessionData] = useState(null);
  const [selectedDate, setselectedDate] = useState(null);
  const [selectedTime, setselectedTime] = useState(null);
  const [cardDetail, setCardDetails] = useState(null);
  const [isChange, setIsChange] = useState(false);
  const [sessionId, setSessionId] = useState(null);
  const [isCareerProject,setIsCareerProject] = useState(false);
  const firestore = firebase.firestore();
  const db = firestore;
  useEffect(() => {
    if (data) {
      setuserData(data?.user);
    }
  }, [data])

  useEffect(() => {
    setStep(formStep ? formStep : isDetail ? 1 : 0)
  }, [isOpenModal, formStep, isDetail]);
  const [serviceErr, setserviceErr] = useState(null);

  const getClassName = () => {
    switch (step) {
      case 1: return 'session-DateTime-popup step-2';
      case 2: return 'add-payment-details-popup step-1';
      case 3: return 'step-3 session-invoice-modal';
      case 4: return ' step-4';
      default: return 'select-service-popup';
    }
  };

  const handleStepChange = () => {
    if (!isDetail && (!selected || selected == null)) {
      setserviceErr("Select a service first");
      return;
    }else {
      session ? setIsNext(true) 
      : ((step === 0) ? (isCareerProject && !selectedService?.detail)
        ?setCareerErr("Add more details about the project is required.")
      :(!isCareerProject && (selectedService?.name==='Career Project Support')
        ? setIsCareerProject(true)
        : (setStep(step + 1),setIsCareerProject(false))
        )
      : (((step === 2) && isChange) 
        ? setStep(step + 1) : setIsNext(true)
      ));
      setserviceErr(null)
    }
  }

  const handleSelectedData = (data, type) => {
    if (type == "date") {
      setselectedDate(data);
    } else {
      setselectedTime(data);
    }
  }


  const fetchCardDetails = async () => {
    setIsLoading(true);
    try {
      const res = await fetch(`/api/stripe/get-card?customerId=${userData?.customerId}`);
      const data = await res.json();
      if (!data.error && data?.card) {
        setCardDetails(data.card);
        setIsChange(true);
      }
    } catch (err) {
      console.log('Error fetching card details');
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    if (((formStep === 2) || (step === 2)) && userData?.customerId) {
      fetchCardDetails();
    }
  }, [step, formStep, userData])
  return (
    <>
      {isDetail ? (
        <NextLink
          href="javascript:void(0)"
          className="dark-blue-btn"
          onClick={() => setToggle(!isOpenModal)}
        >
          Reschedule
        </NextLink>
      ) : !formStep ? (
        <NextLink
          href="javascript:void(0)"
          className="dark-blue-btn"
          onClick={() => setToggle(!isOpenModal)}
        >
          Book Now
        </NextLink>
      ) : (
        formStep === 1 && (
          <NextLink
            href="javascript:void(0)"
            className="dark-blue-btn"
            onClick={() => {
              setSelected(service?.id);
              setselectedService(service);
              setToggle(!isOpenModal);
            }}
          >
            Book Session
          </NextLink>
        )
      )}
      <Modal
        isOpen={isOpenModal}
        isCentered
        size="full"
        onClose={() => {
          setToggle(!isOpenModal);
          setStep(0);
          setSelected(null);
          setselectedService(null);
          setCardDetails(null);
          setSessionData(null);
          setIsCareerProject(false);
        }}
      >
        <ModalOverlay />
        <ModalContent className={getClassName()} px={0}>

          <ModalBody px={3} pb={12}>
            <ModalHeader>
              {((userData?.role === 'mentee') || session) ? (step === 0
                ? isCareerProject?"Add Project Detail":"Select Service"
                : step === 1
                  ? "Select Session Date and Time"
                  : step === 2
                    ? "Add Payment Details"
                    : step === 3
                      ? "Session Invoice"
                      : "") : ''}
              <ModalCloseButton />
            </ModalHeader>
            {isLoading && <Spinner />}
            <Flex w="100%" flexDirection="column" bg="white" maxH="500px">
              {((userData?.role === 'mentee') || session) ? <>
                {step === 0 && (isCareerProject?<Box className="pb-3">
                    <FormControl>
                      <Textarea
                        rows={8}
                        className="form-control w-[100%]"
                        name="detail"
                        placeholder="Type something..."
                        defaultValue={selectedService?.detail}
                        onChange={(e) =>{
                          setselectedService({...selectedService,detail:e.target.value});
                          setCareerErr(e.target.value?null:'More detail about the project is required');
                        }}
                      />
                      <FormHelperText style={{ color: "red" }}>
                        {careerErr}
                      </FormHelperText>
                    </FormControl>
                  </Box>
                  :<ServiceList
                    reviewer={project}
                    isModal
                    selected={selected}
                    setSelected={setSelected}
                    serviceErr={serviceErr}
                    setserviceErr={setserviceErr}
                    setselectedService={setselectedService}
                    mutate={mutate}
                  />
                )}
                {step === 1 && (
                  <SelectDateTimeSessionModal
                    className="calender-wrapper"
                    project={project}
                    sessionData={sessionData}
                    isDetail={isDetail}
                    isNext={isNext}
                    setIsNext={(stp, cdata) => {
                      setIsNext(false);
                      if (stp === 0) {
                        setStep(0);
                        setSelected(null);
                        setselectedService(null);
                        setCardDetails(null);
                        setSessionData(null);
                        setToggle(!isOpenModal);
                      } else if (stp > step) {
                        setStep(step + 1);
                        setSessionData(cdata);
                      }
                    }}
                    setselectedTime={(d, t) => handleSelectedData(d, t)}
                    editSession={session}
                    userData={userData}
                    sessionUser={sessionUser}
                  />
                )}
                {step === 2 && (<>
                  {cardDetail && <CardDetail cardDetail={cardDetail} isChange={isChange} step={step} changed={() => setIsChange(!isChange)} />}
                  {!isChange && cardDetail && <p className="text-center py-1">Or </p>}
                  {!isChange && <AddPaymentDetailModal
                    project={project}
                    isNext={isNext}
                    setIsNext={(stp, cdata) => {
                      setIsNext(false);
                      if (stp > step) {
                        setStep(step + 1);
                        setCardDetails(cdata);
                      }
                    }}
                    mutate={mutate}
                    userData={userData}
                  />}
                </>
                )}
                {step === 3 && (
                  <SessionInvoiceModal
                    project={project}
                    isNext={isNext}
                    setIsNext={(stp, cdata) => {
                      setIsNext(false);
                      setStep(stp);
                      if (stp > step) {
                        setStep(step + 1);
                        setSessionId(cdata);
                      }
                    }}
                    selectedService={selectedService}
                    sessionTime={selectedTime}
                    sessionDate={selectedDate}
                    sessionTimezone={project?.timezone}
                    cardDetail={cardDetail}
                    userData={userData}
                  />
                )}
                {step === 4 && (
                  <ThankYouModal
                    project={project}
                    isNext={isNext}
                    setIsNext={(stp, cdata) => {
                      setToggle(!isOpenModal);
                      setStep(stp);
                    }}
                    selectedService={selectedService}
                    sessionTime={selectedTime}
                    sessionDate={selectedDate}
                    sessionTimezone={project?.timezone}
                    cardDetail={cardDetail}
                    userData={userData}
                    sessionId={sessionId}
                  />
                )}
              </> : <Box className="text-center heading-color font-bold popup-text-new">
                {userData ? (userData?.role !== 'mentee') ? 'Only mentee as user will book the session.' : '' : 'Only logged-in user book the session.'}
              </Box>}
            </Flex>
          </ModalBody>
          <Box className="modal-footer-wrapper">
            <ModalFooter className="custom-modal-footer" style={{ justifyContent: 'center' }}>
              {((userData?.role === 'mentee') || session) ? <>{step < 3 && (
                <Box className="flex !justify-center gap-3">
                  {(((step > 0) && !session && !isCareerProject) || (isCareerProject && (step===0) && !session)) && (
                    <NextLink
                      href="javascript:void(0)"
                      className="blue-outline-btn"
                      onClick={() => isCareerProject?setIsCareerProject(false):((step===1) && (selectedService?.name==="Career Project Support"))?(setStep(step - 1),setIsCareerProject(true)):setStep(step - 1)}
                    >
                      Back
                    </NextLink>
                  )}
                  <NextLink
                    href="javascript:void(0)"
                    className="dark-blue-btn"
                    onClick={handleStepChange}
                  >
                    {isDetail ? "Submit" : "Next"}
                  </NextLink>
                </Box>
              )}
              </> : !userData && <Box className="flex !justify-center gap-3">
                <NextLink
                  href="/login"
                  className="blue-outline-btn"
                  onClick={() => {
                    setToggle(!isOpenModal);
                    setStep(0);
                    setSelected(null);
                    setselectedService(null);
                    setCardDetails(null);
                    setSessionData(null);
                  }}
                >
                  Login
                </NextLink>
              </Box>}
            </ModalFooter>
          </Box>
        </ModalContent>
      </Modal>
    </>
  );
};

export default BookingStepsModal;
