import { mutate } from "swr";
import firebase from "./firebase";
import moment from 'moment';
import axios from "axios";
import { format } from "date-fns";
import { DateTime } from "luxon";
const momentTimezone = require('moment-timezone');
const firestore = firebase.firestore();
const storage = firebase.storage();
import {toast} from "@chakra-ui/react"
export function createUser(uid, data) {
  const validData = {};
  for (const key in data) {
    if (data.hasOwnProperty(key) && data[key] !== undefined) {
      validData[key] = data[key];
    }
  }

  return firestore.collection("users").doc(uid).set(validData, { merge: true });
}

export function deleteUser(uid) {
  return firestore
    .collection("users")
    .doc(uid)
    .delete()
    .then(() => {
      fetch(`/api/delete_admin_user/${uid}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((data) => {
        })
        .catch((error) => {
          console.error("Error deleting user:", error);
        });
      return true;
    });
}

export const getFileBlob = function (url, cb) {
  var xhr = new XMLHttpRequest();
  xhr.open("GET", url);
  xhr.responseType = "blob";
  xhr.addEventListener("load", function () {
    cb(xhr.response);
  });
  xhr.send();
};

export function updateUserEmail(uid, newUserEmail) {
  const docRef = firestore.collection("users").doc(uid);
  return docRef
    .update({
      email: newUserEmail,
    })
    .then(() => {
      return true;
    });
}

export async function getReviewee(id) {
  const revieweeRef = firestore.collection("users").doc(id);

  try {
    const snapshot = await revieweeRef.get();

    if (snapshot.exists) {
      const userData = snapshot.data();
      return userData;
    } else {
      return null;
    }
  } catch (error) {
    console.error("Error getting reviewee:", error.message);
    throw error;
  }
}

export async function getUserByUid(id) {
  const mentorRef = firestore.collection("users").doc(id);

  try {
    const snapshot = await mentorRef.get();

    if (snapshot.exists) {
      const userData = snapshot.data();
      return userData;
    } else {
      return null;
    }
  } catch (error) {
    console.error("Error getting mentors:", error.message);
    throw error;
  }
}

export async function getServicesByUserID(id) {
  const servicesRef = firestore
    .collection("users")
    .doc(id)
    .collection("services");

  try {
    const snapshot = await servicesRef.get();

    if (!snapshot.empty) {
      const servicesData = snapshot.docs.map((doc) => doc.data());
      return servicesData;
    } else {
      return [];
    }
  } catch (error) {
    console.error("Error getting services:", error.message);
    throw error;
  }
}
export async function getStripeDetailsByAccId(accId){
  try {
    const res = await axios.get(`https://api.stripe.com/v1/accounts/${accId}`, {
      headers: {
        Authorization: `Bearer ${process.env.STRIPE_SECRET_KEY}`,
        "Cache-Control": "no-cache",
      },
    });
    if(res?.data){
      return res.data;
    }
  } catch (error) {
    console.log("Err", error);
  }
}

export async function updateUser(rawUser, user) {
  if (!user?.uid) {
    return false;
  } else {
    const userRef = firestore.collection("users").doc(user.uid);
    
    try {
      const userSnapshot = await userRef.get();

      if (userSnapshot.exists) {
        await userRef.update({ ...userSnapshot.data(), ...rawUser });
      }
      
      return true;
    } catch (error) {
      console.log("ERROR", error);
      return false;
    }
  }
}
export async function updateSessionReminderStatus(sid){
  const userRef = firestore.collection("sessions").doc(sid);
    
    try {
      const userSnapshot = await userRef.get();

      if (userSnapshot.exists) {
        await userRef.update({ ...userSnapshot.data(), reminderSent: true });
      }
      
      return true;
    } catch (error) {
      console.log("ERROR", error);
      return false;
    }
}
export async function addToStorage(file){
  if (!file) return;
  let downloadURL;
  const storageRef = storage.ref(`images/_${Date.now()}`,`images/_${Date.now()}`);
  try {
    const snapshot = await storageRef.put(file, { contentType: file.type });
    downloadURL = await snapshot.ref.getDownloadURL();
    return downloadURL;
  } catch (error) {
    console.error("Error uploading file:", error);
    return false;
  }
}
export async function addToStorageFiles(file){
  if (!file) return;
  let downloadURL;
  const storageRef = storage.ref(`files/_${Date.now()}`,`files/_${Date.now()}`);
  try {
    const snapshot = await storageRef.put(file, { contentType: file.type });
    downloadURL = await snapshot.ref.getDownloadURL();
    return downloadURL;
  } catch (error) {
    console.error("Error uploading file:", error);
    return false;
  }
}
export async function getAllUsersForAdmin() {
  try {
    const snapshot = await firestore
      .collection("users")
      .orderBy("first_name")
      .get();
    const users = [];

    const userPromises = snapshot.docs.map(async (doc) => {
      const userData = doc.data();

      const servicesPromise = firestore
        .collection("users")
        .doc(doc.id)
        .collection("services")
        .get();
      const jobsPromise = firestore
        .collection("users")
        .doc(doc.id)
        .collection("jobs")
        .get();

      const [servicesSnapshot, jobsSnapshot] = await Promise.all([
        servicesPromise,
        jobsPromise,
      ]);

      // Map services and jobs
      const services = servicesSnapshot.docs.map((serviceDoc) => ({
        id: serviceDoc.id,
        ...serviceDoc.data(),
      }));

      const jobs = jobsSnapshot.docs.map((jobDoc) => ({
        id: jobDoc.id,
        ...jobDoc.data(),
      }));

      return { id: doc.id, ...userData, services, jobs };
    });

    const allUsers = await Promise.all(userPromises);

    return allUsers;
  } catch (error) {
    console.error("Error fetching users:", error);
    throw new Error("Failed to fetch users");
  }
}

export async function getAllSkills(){
  const snapshot = await firestore.collection("skills").orderBy("name").get();
  const skills = [];
  snapshot.forEach((doc)=>{
    skills.push({id: doc.id, ...doc.data()})
  })
  return skills;
}

export async function getAllIndustries() {
  const snapshot = await firestore.collection("industries").orderBy("order").get();
  const industry = [];
  snapshot.forEach((doc) => {
    industry.push({ id: doc.id, ...doc.data() });
  });
  return industry;
}

export async function getAllServices() {
  const snapshot = await firestore
    .collection("Services")
    .orderBy("name")
    .get();
  const services = [];
  snapshot.forEach((doc) => {
    services.push({ id: doc.id, ...doc.data() });
  });
  return services;
}

export async function getMentorFromId(id){
  const snapshot = await firestore.collection("users").where("uid", `==`, id).get();
  const users = [];
   snapshot.forEach((doc) => {
     users.push({ id: doc.id, ...doc.data() });
   });

   return users;
}

export async function switchUserRole(id,status){
   const snapshot = await firestore
     .collection("users")
     .where("uid", `==`, id)
     .get();
  const userDoc = snapshot.docs[0];
  const userId = userDoc.id;
  await firestore.collection("users").doc(userId).update({
    is_activated: status
  })
  mutate('users');
}



export async function addContactInquery(rawUser) {
  const userRef = firestore.collection("contacts");
  try {
    await userRef.add({...rawUser});
    return true;
  } catch (error) {
    return false;
  }
}
export async function getAllContactsForAdmin(){
  const snapshot = await firestore
    .collection("contacts")
    .orderBy("createdAt","desc")
    .get();
  const contacts = [];

  snapshot.forEach((doc) => {
    contacts.push({ id: doc.id, ...doc.data() });
  });

  return contacts;
}
export async function getAllSettings(){
  const snapshot = await firestore
    .collection("settings")
    .get();
  const settings = [];

  snapshot.forEach((doc) => {
    settings.push({ id: doc.id, ...doc.data() });
  });

  return settings;
}
export async function getAllSessions(){
  const snapshot = await firestore
    .collection("sessions")
    .orderBy("sessionId","desc")
    .get();
  const sessions = [];

  snapshot.forEach((doc) => {
    sessions.push({ id: doc.id, ...doc.data() });
  });

  return sessions;
}
export async function addCommissions(rawUser) {
  const userRef = firestore.collection("commissions");
  try {
    await userRef.add({...rawUser});
    return true;
  } catch (error) {
    return false;
  }
}
export async function getAllCommissions(){
  const snapshot = await firestore
    .collection("commissions")
    .get();
  const commissions = [];

  snapshot.forEach((doc) => {
    commissions.push({ id: doc.id, ...doc.data() });
  });

  return commissions;
}

export async function getJobs(userId){
  const snapshot = await firestore
    .collection(`users/${userId}/jobs`)
    .get();
  const jobs = [];

  snapshot.forEach((doc) => {
    jobs.push({ id: doc.id, ...doc.data() });
  });

  return jobs;
}
export async function getServices(userId){
  const snapshot = await firestore
    .collection(`users/${userId}/services`)
    .get();
  const services = [];

  snapshot.forEach((doc) => {
    services.push({ id: doc.id, ...doc.data() });
  });

  return services;
}
export const getUsersFromDB = async () => {
  const snapshot = await firestore.collection('users')
  .where('is_activated', '==', true).get();
  if (snapshot.empty) {
    return [];
  }
  return snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
};
export const getMentorsFromDB = async () => {
  const snapshot = await firestore.collection('users')
  .where('role', '==', 'mentor')
  .where('is_approved', '==', true) 
  .where('is_activated', '==', true).get();
  if (snapshot.empty) {
    return [];
  }
  return snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
};
export const getSessionsFromDB= async () => {
  const snapshot = await firestore.collection('sessions')
  .where('status', '==', 'accepted').get();
  if (snapshot.empty) {
    return [];
  }
  return snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
};

export function convert_to_12hourformat(time) {
  const [hourStr, minuteStr, period] = time?.match(/^(\d{2}):(\d{2})([ap]m)$/i).slice(1);
  let hour = parseInt(hourStr, 10);
  const minute = parseInt(minuteStr, 10);
  if (period.toLowerCase() === 'pm' && hour === 0) {
      hour = 12;
  }
  const formattedHour = (hour % 12 || 12).toString().padStart(2, '0');
  const formattedMinute = minute < 10 ? '0' + minute : minute;
  const formattedPeriod = hour >= 12 ? 'pm' : 'am';

  return `${formattedHour}:${formattedMinute}${formattedPeriod}`;
}
export function get12_hours_format(time){
 if(time!='any'){
     const t=time.split('-');
     const startTime = t[0];
     const endTime = t[1];
     const formattedStartTime = convert_to_12hourformat(startTime);
     const formattedEndTime = convert_to_12hourformat(endTime);
     time=formattedStartTime + '-' + formattedEndTime;
 }else{
     time='Any'
 }
 return time;
}

export function convertTo12HourFormat(time24) {
 if(time24){
     const [hours, minutes] = time24.split(':');
     let period = 'AM';
     let hour = parseInt(hours, 10);
     const minute = parseInt(minutes, 10);
     if (hour === 0) {
         hour = 12;
     } else if (hour >= 12) {
         period = 'PM';
         if (hour > 12) {
             hour -= 12;
         }
     }
     const formattedHour = (hour % 12 || 12).toString().padStart(2, '0');
     const formattedMinute = (minute < 10 ? '0' + minute : minute) || '00';
     time24=`${formattedHour}:${formattedMinute} ${period}`
 }
 

 return time24;
}
export const convertTime12to24 = (time12h) => {
 const [time, modifier] = time12h.split(' ');

 let [hours, minutes] = time.split(':');

 if (hours === '12') {
   hours = '00';
 }

 if (modifier === 'PM') {
   hours = parseInt(hours, 10) + 12;
 }
 return `${hours}:${minutes}`;
}
export const getTimeFormat=(t)=>{
 let time=t;
 if(time){
     let arr=t.split('-');
     let arr1=arr[0].split(':');
     let arr2=arr[1].split(':');
     time=((arr1[0]==='00')?'12':arr1[0])+':'+arr1[1]+'-'+((arr2[0]==='00')?'12':arr2[0])+':'+arr2[1]
 }
 return time;
} 
export function parseTime(timeStr) {
 let [time, period] = timeStr?.match(/(\d{2}:\d{2})(AM|PM)/).slice(1, 3);
 let [hours, minutes] = time?.split(':').map(Number);
 if (period === 'PM' && hours !== 12) {
     hours += 12;
 } else if (period === 'AM' && hours === 12) {
     hours = 0;
 }
 return new Date(1970, 0, 1, hours, minutes);
}
export const getDayTimes=(times)=>{
 times.sort((a, b) => {
     let [startA] = a.split('-').map(parseTime);
     let [startB] = b.split('-').map(parseTime);
     return startA - startB;
 });
 return times;
}
export const formatDateWithTimezone = (date, formatString) => {
 let delDate = moment(date);
   delDate = delDate.add(1, 'days'); 
   return delDate.format(formatString)
};

export function handle_imageupload(name, event) {
  let file = event.target.files[0];
  const maxSize = size * 1024 * 1024;
  const {type, size} = event.target.files[0]
  const allowedFormats = [
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "image/jpeg",
    "image/png",
    "application/pdf",
  ];
  if (type === "image" && !file.type.startsWith("image/")) {
    toast.error("Please upload an image file.");
  } else if (type === "doc" && !allowedFormats.includes(file.type)) {
    toast.error(
      "Please upload a file of type: .doc, .docx, .jpg, .jpeg, .png, or .pdf."
    );
  } else if (type === "video" && !file.type.startsWith("video/")) {
    toast.error("Please upload an video file.");
  } else if (file.size > maxSize) {
    toast.error(
      `The ${
        type === "image" ? "image" : type === "video" ? "video" : "document"
      } size should not exceed ${size}MB.`
    );
  } else {
    form[name] = file;
    document[name] = URL.createObjectURL(file);
    props.errors[name] = null;
    file_type[name] = file.type;
    submit_Document();
  }
}
export const formattedDateSelected = (date,timezone)=>{
  if(!date) return ;
  const timestamp = date?.seconds;
  const nanoseconds = date?.nanoseconds;
  const date1=new Date(timestamp * 1000 + nanoseconds / 1e6);
  return timezoneBasedDate(date1,timezone);
}

export const sendEmail=async(api,data)=>{
  const response = await fetch(`/api/sendgrid/${api}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
}
export const convertToMinutes = (hour, minute, meridiem) => {
  let totalMinutes = hour * 60 + minute;
  if ((meridiem === 'PM') && hour < 12) {
    totalMinutes += 12 * 60;
  }
  if ((meridiem === 'AM') && hour === 12) {
    totalMinutes -= 12 * 60;
  }
  return totalMinutes;
};
export const getUserBySessionUser = (uid) => {
  const docRef = firestore.collection('users').doc(uid);
  const docSnapshot = docRef.get();
  console.log(docSnapshot)
  if (!docSnapshot.exists) {
    return null;
  }
  let uData = { ...docSnapshot.data(), id: docSnapshot.id };
  return uData; 
};
export const getUserRate = (allSessn)=>{
  const reviewCount=[
    {rate:5, count: 0},
    {rate:4, count: 0},
    {rate:3, count: 0},
    {rate:2, count: 0},
    {rate:1, count: 0},
  ];
  let rcount=[...reviewCount];
    allSessn?.forEach(sn => {
      sn.rating=sn?.rating?sn?.rating:0;
      rcount[sn.rating<=5?5-sn.rating:sn.rating].count += 1;
    });
    const totalRatings = rcount.reduce((acc, { count }) => acc + count, 0);
    const sumOfRatings = rcount.reduce((acc, { rate, count }) => acc + (rate * count), 0);
    const overallRating = totalRatings ? (sumOfRatings / totalRatings).toFixed(1) : 0;
    return overallRating;
}
export async function uploadRecording(idProject, recordingBlob) {
  const storageRef = firebase.storage().ref();
  const projectRef = firestore.collection("sessions").doc(idProject);

  return new Promise((resolve, reject) => {
    const fileName = `recording_${idProject}.mp4`; // Adjust the file extension based on your recording format
    const recordingStorageRef = storageRef.child(`${idProject}/recordings/${fileName}`);

    recordingStorageRef.put(recordingBlob, { contentType: 'video/mp4' })
      .then((snapshot) => {
        snapshot.ref.getDownloadURL().then((downloadURL) => {
          projectRef.update({
            videoUrl: downloadURL,
            reviewDate: new Date()
          }).then(() => {
            resolve(downloadURL); // Resolve with the download URL
          }).catch((error) => {
            reject(error);
          });
        });
      })
      .catch((error) => {
        reject(error);
      });
  });
}


export async function addUrlPathVideo(idProject, pathUrl) {
  const project = firestore.collection("sessions").doc(idProject);
  project.update({
    videoUrl: pathUrl,
  });
  return true;
}
export function updateBooking(projectId, newValue) {
  const booking = firestore.collection("sessions").doc(projectId);
  booking.update(newValue);
}
export async function getBooking(projectId) {
  const bookingRef = firestore.collection("sessions").doc(projectId);

  try {
    const snapshot = await bookingRef.get();
    if (snapshot.exists) {
      const bookingData = snapshot.data();
      return bookingData;
    } else {
      return null;
    }
  } catch (error) {
    console.error("Error getting booking:", error.message);
    throw error;
  }
}
export function onBookingDataChange(projectId, callback) {
  const bookingRef = firestore.collection("sessions").doc(projectId);
  return bookingRef.onSnapshot((snapshot) => {
    if (snapshot.exists) {
      callback(snapshot.data());
    } else {
      console.error('No such document!');
    }
  });
}
export function reviewIsClosed(idProject, val) {
  const project = firestore.collection("sessions").doc(idProject);
  return project
    .update({
      closedReview: val,
    })
    .then(() => {
      return true;
    });
}
export const getTimeFrom12Hour = (date, startTimeStr, timezone) => {
  date = momentTimezone(date).format('YYYY-MM-DD');
  const [time, modifier] = startTimeStr.match(/(\d{1,2}:\d{2})(AM|PM)/).slice(1);
  let [hours, minutes] = time.split(':').map(Number);
  if (modifier === 'PM' && hours !== 12) hours += 12;
  if (modifier === 'AM' && hours === 12) hours = 0;
  const dateTimeStr = `${date} ${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
  const mentorStartTime = momentTimezone.tz(dateTimeStr, 'YYYY-MM-DD HH:mm', timezone);
  if (!mentorStartTime.isValid()) {
    console.error('Invalid time or timezone:', dateTimeStr, timezone);
    return null;
  }
  const convertedTime = mentorStartTime.utc().local().format('hh:mm A');
  return convertedTime;
};
export function formatTimeRangeToTimezone(timeRange, fromTimezone, totimezone, formattedDate) {
  const [startTime, endTime] = timeRange.split("-");
  let localStartTime = getTimeFrom12Hour(formattedDate,startTime, fromTimezone);
  let localEndTime = getTimeFrom12Hour(formattedDate,endTime, fromTimezone);

  return `${localStartTime?.replace(' ','')}-${localEndTime?.replace(' ','')}`;
}
export const convertTimeToTimezone = (date, timeStr, fromTimezone, toTimezone) => {
  // Parse the date into YYYY-MM-DD format
  const dateOnly = momentTimezone(date).format('YYYY-MM-DD');

  // Extract time and modifier (AM/PM)
  const [time, modifier] = timeStr.match(/(\d{1,2}:\d{2})(AM|PM)/).slice(1);
  let [hours, minutes] = time.split(':').map(Number);

  // Convert 12-hour to 24-hour format
  if (modifier === 'PM' && hours !== 12) hours += 12;
  if (modifier === 'AM' && hours === 12) hours = 0;

  // Combine date and time in the source timezone
  const sourceDateTimeStr = `${dateOnly} ${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
  const sourceTime = momentTimezone.tz(sourceDateTimeStr, 'YYYY-MM-DD HH:mm', fromTimezone);

  if (!sourceTime.isValid()) {
    console.error('Invalid time or timezone:', sourceDateTimeStr, fromTimezone);
    return null;
  }

  // Convert to the target timezone
  const targetTime = sourceTime.clone().tz(toTimezone);

  // Return the formatted time
  return targetTime.format('hh:mm A');
};

// Converts a time range from one timezone to another
export const convertTimeRangeToTimezone = (timeRange, fromTimezone, toTimezone, date) => {
  if (!timeRange || !fromTimezone || !toTimezone || !date) {
    console.error('Invalid input parameters.');
    return null;
  }

  // Split the time range into start and end times
  const [startTime, endTime] = timeRange.split('-');
  if (!startTime || !endTime) {
    console.error('Invalid time range format:', timeRange);
    return null;
  }

  // Convert start and end times to the target timezone
  const targetStartTime = convertTimeToTimezone(date, startTime, fromTimezone, toTimezone);
  const targetEndTime = convertTimeToTimezone(date, endTime, fromTimezone, toTimezone);

  if (!targetStartTime || !targetEndTime) {
    console.error('Failed to convert time range.');
    return null;
  }

  // Format and return the converted time range
  return `${targetStartTime?.replace(' ','')}-${targetEndTime?.replace(' ','')}`;
};
export const convertToDateWithExtraFifteenMinute = (sessionDate) => {
  let sdate=new Date(sessionDate);
  sdate.setMinutes(sdate.getMinutes() + 15 + 60);
  return sdate;
};
export const cancelBookingWithRefundDb=async(isVar,session,userData,sessionUser,toast,isTime)=>{
    const res = await fetch(`/api/stripe/refund`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: !isVar && isTime && (userData?.role==='mentee')?JSON.stringify({
        paymentIntentId: session?.paymentIntent,
        amount: Math.round((session?.service?.rate/2)* 100)
      }):JSON.stringify({
        paymentIntentId: session?.paymentIntent,
      })
    });
    const data = await res.json();
    if(data?.error && !isVar){
      toast("Cancel session booking failed. Try again!", "error", '', true);
    }else{
      let updatedData = { ...session,mentee:null,mentorData:null,status:'cancelled',rescheduleBy:userData?.role};
      await firestore.collection('sessions').doc(session?.id).update(updatedData);
      if(!isVar) {
        toast("Successfully cancelled!", "success", '', true);
        if(session?.status==='accepted'){
          const originalDate = new Date(session?.reminderSessionDate);
          const newDate = new Date(originalDate); 
          newDate.setMinutes(newDate.getMinutes() + 15);
          await sendEmail('cancel-booking',
            {
              email:sessionUser?.email,
              uemail:userData?.email,
              mname:`${userData?.first_name} ${userData?.role==='mentee'?userData?.last_name:userData?.last_name?.slice(0,1)}`,
              name:`${sessionUser?.first_name} ${sessionUser?.last_name}`,
              date:userData?.role==='mentor'?momentTimezone.utc(newDate.toISOString()).tz(session?.menteeTimezone).format('dddd, MMMM Do YYYY, h:mm A'):momentTimezone.utc(newDate.toISOString()).tz(session?.userTimezone).format('dddd, MMMM Do YYYY, h:mm A'),
              time:userData?.role==='mentor'?convertTimeRangeToTimezone(session?.sessionTime,session?.userTimezone,session?.menteeTimezone,new Date(session?.sessionDate?.seconds * 1000 + session?.sessionDate?.nanoseconds / 1e6)):session?.sessionTime,
              role:userData?.role
            }
          );
        }
      }
    }
}
export const timezoneBasedDate=(date,timezone)=>{
  return new Intl.DateTimeFormat('en-US', {
      timeZone: timezone,
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
  }).format(date);
}
