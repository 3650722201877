import React, {useEffect, useState} from "react";
import {
    Box,
} from "@chakra-ui/react";
import NextLink from "next/link";
import { FaStar } from "react-icons/fa";
import BookingStepsModal from "./modal/BookingModals";
import ReadMoreLess from "@/utils/ReadMoreLess";
import firebase from "firebase";
import {getUserBySessionUser, getUserRate} from "@/lib/db";
const ServiceList = ({
  reviewer,
  isModal,
  selected,
  setSelected,
  serviceErr,
  setselectedService,
  setSelTab,
  setSubTab
}) => {
  const firestore = firebase.firestore();
  const db = firestore;
  const [sessions,setSessions]=useState([]);
  const handleServiceSelect = (service) => {
    setSelected(service?.id);
    setselectedService(service)
  };
  useEffect(()=>{
    const unsubscribe = db.collection(`sessions`)
      .onSnapshot(async(snapshot) => {
        let cusers=[];
        await snapshot.docs.forEach(async(doc) => {
          let docs={
            ...doc.data(),
            id: doc.id,
          };
          if(docs && ((docs?.mentor===reviewer?.uid) || (docs?.mentor===reviewer?.id)) && (docs?.status==='completed')){
            cusers.push({...docs});
            setSessions(cusers);
          }
        });
      
      }, (error) => {
        console.error('Error fetching sessions:', error);
      });
    return () => unsubscribe(); 
  }, [reviewer]);
  
  return (
    <Box
      className={`grid grid-cols-1 sm:grid-cols-2 pb-4 ${
        isModal ? "lg:grid-cols-3" : "lg:grid-cols-2"
      } resume-new-modal css-1xdhyk6`}
    >
      {reviewer &&  reviewer?.services?.map((service) => (
        <Box
          key={service?.id}
          className={`resume-building-service-col !mb-0 ${
            isModal && selected === service?.id ? "active-service" : ""
          }`}
          sx={isModal && { cursor: "pointer" }}
          onClick={() => {
            isModal && handleServiceSelect(service);
          }}
        >
          <h4 className="heading-color">{service?.name}</h4>
          <p className="my-2 flex !text-yellow-700">
            <FaStar className="mr-1 text-yellow-700" />{getUserRate(sessions?.filter(r=>(r?.service?.name===service?.name) && r?.rating)) || 0}
            <span className="underline ml-3 gray-color"> ( {sessions?.filter(r=>(r?.service?.name===service?.name) && r?.rating)?.length || 0} Reviews)</span>
          </p>

          <ul className="ps-0 mb-0">
            <li className="gray-color">
              Rate:
              <span className="text-black font-medium">
                ${service?.rate}/hr
              </span>
            </li>
            <li className="gray-color">
              Session Completed:
              <span className="text-black font-medium">
                {" "}
                {sessions?.filter(r=>r?.service?.name===service?.name)?.length || 0}
              </span>
            </li>
          </ul>
          <ReadMoreLess description={service?.description} descLength={70} extraClass="my-2 flex flex-col"/>
          {!isModal && (
            <Box className="flex gap-4 mt-4">
              <BookingStepsModal
                project={reviewer}
                service={service}
                formStep={1}
              />

              <NextLink href="javascript:void(0)" className="blue-outline-btn" onClick={()=>{
                setSelTab(1);
                setSubTab(service?.name);
              }}>
                See Reviews
              </NextLink>
            </Box>
          )}
        </Box>
      ))}
      {serviceErr && <p className="text-red-500">{serviceErr}</p>}
    </Box>
  );
};

export default ServiceList;
