import React from "react";
import { Flex, Box,Button } from "@chakra-ui/react";
import MentorAndSession from "./MentorAndSession";
import { useRouter } from 'next/router';
import NextLink from "next/link";
import ReadMoreLess from "@/utils/ReadMoreLess";
const ThankYouModal = ({ 
  project, 
  selectedService,
  sessionTime,
  sessionDate,
  sessionTimezone,
  setIsNext,
  sessionId,
  userData={userData}
}) => {
  const router=useRouter();
  const goToPage = async (page) => {
      setIsNext(0,null);
      router.push(page);
  };
  return (
    <Flex w="100%" flexDirection="column" bg="white" maxH="500px">
      <Box className="mx-auto">
        <img className="w-[70%] mx-auto" src="/assets/request-sent-img.png"></img>
      </Box>
      <h2 className="heading-color text-center mb-2">Your Request has been sent to the Mentor</h2>
      <p className="text-black mb-4">Here’s what happens next:</p>
      <p className="text-black mb-1">1. Mentor Acceptance: Your mentor will review and accept your session request.</p>
      <p className="text-black">2. Join Session: Once your request is accepted, simply join the session at the scheduled time from “My Session”</p>
      <Box className="thankyou-mentor">
        <MentorAndSession 
          project={project}
          selectedService={selectedService}
          sessionTime={sessionTime}
          sessionDate={sessionDate}
          sessionTimezone={sessionTimezone}
          sessionId={sessionId}
          isThanks={true}
          userData={userData}
        />
      </Box>
      {(selectedService?.name==="Career Project Support") && selectedService?.detail &&<p>
        <h5 className="heading-color">Project Detail:{" "}</h5>
        <ReadMoreLess description={selectedService?.detail} descLength={490} extraClass="my-2 flex flex-col"/>
      </p>}
      <Box className="flex justify-center gap-3">
        <Button id="submitForm" type="button" className="dark-blue-btn" onClick={(e)=>goToPage('/my-sessions')}>
          My Sessions
        </Button>
      </Box>
      <p className="text-center py-5">Session ID - <NextLink href={`/my-session/${sessionId}`} className="underline heading-color">{sessionId}</NextLink></p>
    </Flex>
  );
};

export default ThankYouModal;
